import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./routes/Routes";
import {socket} from "api";
import queryClient from "api/query";
import {init} from "api/loader";

import { QueryClientProvider } from 'react-query';

console.log({openpay: process.env.REACT_APP_OPENPAY_ID, env:process.env.NODE_ENV})
if(window.OpenPay){
	window.OpenPay.setId(process.env.REACT_APP_OPENPAY_ID);
	window.OpenPay.setApiKey(process.env.REACT_APP_OPENPAY_PUBLIC_KEY);
	window.OpenPay.setSandboxMode(process.env.NODE_ENV!=="production");
}
// import "./App.css";
require("analytics");
function App() {
	React.useEffect(()=>{
		init();
	},[]);
	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<Switch>
					<Routes />
				</Switch>
			</Router>
		</QueryClientProvider>
	);
}

export default App;
