import React from "react";
import { Route } from "react-router-dom";
import "./Routes.scss";
import loadable from "@loadable/component";
import {trackPage} from "analytics";
import Header from "components/Header/Header";


import {userStore} from 'api/store';
import { useState } from '@hookstate/core';

import { CircularProgress } from "@material-ui/core";
const fallback = ()=> <CircularProgress />

const Home = loadable(() => import("../pages/Home/Home"),fallback);
const NavBar = loadable(() => import("../components/NavBar/NavBar"));
const Clientes = loadable(() => import("../pages/Clientes/TablaClientes"),fallback);
const Cliente = loadable(() => import("../pages/Clientes/Cliente"),fallback);
const Mercancias = loadable(() => import("../pages/TiposMercancias/TablaMercancias"),fallback);
const Unidades = loadable(() => import("../pages/TiposUnidades/TablaUnidades"),fallback);
const Transportistas = loadable(() => import("../pages/Transportistas/TablaTransportistas"),fallback);
const Transportista = loadable(() => import("../pages/Transportistas/Transportista"),fallback);
const PreciosForaneos = loadable(() => import("../pages/PreciosForaneos/TablaPrecios"),fallback);
const PreciosLocales = loadable(() => import("../pages/PreciosLocales/TablaPrecios"),fallback);
const TabsViajes = loadable(() => import("../pages/Viajes/TabsViajes"),fallback);
const Viaje = loadable(() => import("../pages/Viajes/Viaje"),fallback);
const TablaPagos = loadable(() => import("../pages/Pagos/TablaPagos"),fallback);
const TablaSeguros = loadable(() => import("../pages/Seguros/TablaSeguros"),fallback);
const DetalleSeguro = loadable(() => import("../pages/Seguros/Seguro"),fallback);
const TablaPlanes = loadable(() => import("../pages/Planes/TablaPlanes"),fallback);
const Pago = loadable(() => import("../pages/Clientes/Tabs/Pagos/Pago"),fallback);
const PagoTransportista = loadable(() => import("../pages/Transportistas/Tabs/Pagos/Pago"),fallback);
const CuentasXCobrar = loadable(() => import("../pages/CuentasXCobrar/Resumen"),fallback);

const Login = loadable(() => import("../pages/Login/Login"),fallback);
const Registro = loadable(() => import("../pages/Login/Registro"),fallback);
const Forgot = loadable(() => import("../pages/Login/Olvide"),fallback);
const Reset = loadable(() => import("../pages/Login/Reset"),fallback);
const Config = loadable(() => import("../pages/Config/Config"),fallback);
const CalendarioViajes = loadable(() => import("../pages/Viajes/CalendarioViajes"),fallback);
const PrivateRoute = loadable(() => import("./PrivateRoute/PrivateRoute"));
const PublicRoute = loadable(() => import("./PublicRoute/PublicRoute"));

const Routes = () => {
	const state = useState(userStore);
	const status = state.status.get();

	// const status = state.status.get();
	const loggedIn = state.loggedIn.get();

	// const prevPath = usePrevious(window.location.pathname);
	React.useEffect(()=>{
		let path = window.location.pathname;
		const myregexp = /[0-9a-fA-F]{24}/g;
		path = path.replace(myregexp, "id");
		trackPage(path);
	},[window.location.pathname]);

	let RNavBar = window.location.pathname === "/login" || window.location.pathname === "/registro" || window.location.pathname.startsWith("/reset") || window.location.pathname.startsWith("/reset") || window.location.pathname === "/forgot" || window.location.origin === "/consultaonline" ? null : <><NavBar /><Header title={"Transporta"} /></>;
	if (status==="loading")return <>Loading</>;
	return (
		<>
			<div className="container">
				<PublicRoute loggedIn={loggedIn} exact path="/login" component={Login} />
				<PublicRoute loggedIn={loggedIn} exact path="/registro" component={Registro} />
				<PublicRoute loggedIn={loggedIn} exact path="/forgot" component={Forgot} />
				<PublicRoute loggedIn={loggedIn} exact path="/reset/:token/:idNutriologo" component={Reset} />

				<div className={(window.location.pathname === "/login" || window.location.pathname === "/registro"||window.location.pathname === "/forgot") ? "": "container-app"}>
					{RNavBar}
					

					<PrivateRoute loggedIn={loggedIn} exact path="/" component={Home} />
					<PrivateRoute loggedIn={loggedIn} path="/clientes" component={Clientes} />
					<PrivateRoute loggedIn={loggedIn} path="/clientes-saas" component={Clientes} saas />
					
					<PrivateRoute loggedIn={loggedIn} path="/cliente/:idCliente/pago/new" component={Pago} />
					<PrivateRoute loggedIn={loggedIn} exact path="/cliente/:id/:seccion?" component={Cliente} />
					<PrivateRoute loggedIn={loggedIn} path="/seguros" component={TablaSeguros} saas={false} />
					<PrivateRoute loggedIn={loggedIn} path="/seguro/:id" component={DetalleSeguro} saas={false} />
					<PrivateRoute loggedIn={loggedIn} path="/viajes-calendario" component={CalendarioViajes} saas={false} />
					<PrivateRoute loggedIn={loggedIn} path="/viajes/:seccion?" component={TabsViajes} saas={false} />
					<PrivateRoute loggedIn={loggedIn} path="/viajes-saas/:seccion?" component={TabsViajes} saas />
					<PrivateRoute loggedIn={loggedIn} path="/viaje/:id/:seccion?" component={Viaje} />
					<PrivateRoute loggedIn={loggedIn} path="/dedicados/:seccion?" component={TabsViajes} />
					<PrivateRoute loggedIn={loggedIn} path="/dedicado/:id/:seccion?" component={Viaje} />
					<PrivateRoute loggedIn={loggedIn} exact path="/transportistas" component={Transportistas} />
					<PrivateRoute loggedIn={loggedIn} exact path="/transportistas-saas" component={Transportistas} saas />
					<PrivateRoute loggedIn={loggedIn} path="/transportistas/:id/:seccion?" component={Transportista} />
					<PrivateRoute loggedIn={loggedIn} path="/transportista/:id/pago/new" component={PagoTransportista} />
					<PrivateRoute loggedIn={loggedIn} path="/cuentas" component={CuentasXCobrar} />

					
					<PrivateRoute loggedIn={loggedIn} path="/unidades" component={Unidades} />
					<PrivateRoute loggedIn={loggedIn} path="/mercancias" component={Mercancias} />
					<PrivateRoute loggedIn={loggedIn} path="/precios-locales" component={PreciosLocales} />
					<PrivateRoute loggedIn={loggedIn} path="/precios-foraneos" component={PreciosForaneos} />
					<PrivateRoute loggedIn={loggedIn} path="/pagos" component={TablaPagos} />
					<PrivateRoute loggedIn={loggedIn} path="/config" component={Config} />
					<PrivateRoute loggedIn={loggedIn} path="/planes" component={TablaPlanes} />

				</div>
			</div>
		</>
	);
};

export default Routes;
