import queryClient from "./query";
import {setGlobalState} from "./store";
const axios = require("axios");
const token = window.localStorage.getItem(process.env.REACT_APP_KEY);
// export const socket = io.connect("http://localhost:3000/consultas_online");
 


// export const socket = io("http://localhost:3000/consultas_online");

let instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 120000,
});

export function init(token){
	instance.defaults.headers["Authorization"] = `Bearer ${token}`;
}
export const Authorization = `Bearer ${token}`;
export function get(uri, params){
	return new Promise((resolve, reject)=>{
		instance.get(uri,{params}).then(function (response) {
			return resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}

export function post(uri, data, options){
	console.log("post")
	return new Promise((resolve, reject)=>{
	console.log("post2")

		instance.post(uri, data, options).then(function (response) {
	console.log("post3")

			console.log({response})
			return resolve(response.data);
		}).catch(error=>{
			console.log(error)
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function put(uri, data){
	return new Promise((resolve, reject)=>{
		instance.put(uri, data).then(function (response) {
			return resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function remove(uri, data){
	return new Promise((resolve, reject)=>{
		instance.delete(uri, data).then(function (response) {
			return resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function upload(uri, files){
	return new Promise((resolve, reject)=>{
		// const url = 'http://localhost:3000/patients/img';
		const formData = new FormData();
		if(Array.isArray(files)){
			files.forEach(f => {
				formData.append(f.name,f.file);
			});
		}
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			},
			timeout:180000
		};
		instance.post(uri, formData, config).then(function (response) {
			resolve(response.data);
		}).catch(function (error){
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	}); 
}
export async function download(uri, data, options, filename){
	
	return new Promise((resolve, reject)=>{
		instance.post(uri, data, options).then(function (response) {
		

			const blob = new Blob([response.data]);
			const link = document.createElement("a")
			link.href = URL.createObjectURL(blob)
			link.download = filename;
			link.click();
			setTimeout(() => URL.revokeObjectURL(link.href), 0)

			resolve(response.data);
		}).catch(error=>{
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});

	
}
export const track = console.log
export const login = (data) => post("/super-login",data, { withCredentials: true });
export const logout = () => {
	console.log("LOGGING OUT")

	// setGlobalState("loggedIn",false)

	init();
	queryClient.invalidateQueries();

	return post("/cliente-logout");
};
export const getTransportista = ({queryKey}) => {
	return get("/admin/transportistas/"+queryKey[1]);
};

export const getCliente = ({queryKey}) => {
	return get("/admin/clientes/"+queryKey[1]);
};


export const registro = (data) => post("/transportista-registro",data, { withCredentials: true });
export const getTiposUnidad = () => get("/tipos-unidad");
export const getTiposMercancia = () => get("/tipos-mercancia");
export const getViajes = ({queryKey}) => {
	console.log({queryKey})
	return get("/admin/viajes-dedicados", {queryKey});
}
export const getConsolidado = ({queryKey}) => get(`/admin/viajes-consolidados/${queryKey[1]}`)
export const getDedicado = ({queryKey}) => get(`/admin/viajes-dedicados/${queryKey[1]}`);
export const getHome = ({queryKey}) => get("/admin/home", {fechas:queryKey[1]});
export const getUser = () => get("/admin/me");
export const exportarViajes = (data) => (console.log(data),download("/admin/viajes-dedicados/exportar",data, { withCredentials: true }, "viajes.csv"));
export const exportarSeguros = (data) => (console.log(data),download("/admin/seguros/exportar",data, { withCredentials: true }, "seguros.csv"));

export const crearPdfEdoCuenta = async (idPago) => {
	const response = await post(`/admin/pagos/${idPago}/pdf`,{},{responseType: 'blob'})
	const fileURL = URL.createObjectURL(response);
	const fileLink = document.createElement('a');
	fileLink.href = fileURL;
	fileLink.setAttribute('download', 'filename.pdf'); // Replace with your file name
	document.body.appendChild(fileLink);
	fileLink.click();
	fileLink.parentNode.removeChild(fileLink);
}
export const crearPdfEdoCuentaTransportista = async (idPago) => {
	const response = await post(`/admin/pagos/transportista/${idPago}/pdf`,{},{responseType: 'blob'})
	const fileURL = URL.createObjectURL(response);
	const fileLink = document.createElement('a');
	fileLink.href = fileURL;
	fileLink.setAttribute('download', 'filename.pdf'); // Replace with your file name
	document.body.appendChild(fileLink);
	fileLink.click();
	fileLink.parentNode.removeChild(fileLink);
}




export const crudFunctions = (type, {replacements, parent_id}={}) => {
	const tipo = type.url;
	const tail = type.tail_url;
	const url = tail ? `${tipo}/${parent_id}/${tail}` : `${tipo}`;
	return {
		getAll: ({queryKey})=>{
			if(queryKey.includes("ref")) return get(`/${url}/?all=true`);
			return get(`/${url}`, {page:queryKey[1]?.page, search:queryKey[1]?.filter, sort:queryKey[1]?.sort, pageSize:queryKey[1]?.pageSize});
		},
		getPaginated: ({queryKey})=>{
			return get(`/${tipo}`,{page:queryKey[1].page, search:queryKey[1].filter, sort:queryKey[1].sort});
		},
		getOne: (id)=>{
			return get(`/${url}/${id}`);
		},
		getOneQueryKey: ({queryKey})=>{
			return get(`/${url}/${queryKey[1]}`);
		},
		insert:async ({files, ...data})=>{
			let response = await post(url, data);
			if(files){
				response = await upload(`/${url}/${response.data._id}/files`, files);
			}
			queryClient.invalidateQueries([type.name, parent_id]);

			return response;
		},
		update:async (id, {files, ...data})=>{
			let response = await put(`/${url}/${id}`, data);
			if(files){
				response = await upload(`/${url}/${id}/files`, files);
			}
			queryClient.invalidateQueries([type.name, parent_id]);
			return response;
		},
		trash:async(id)=>{
			let r = await put(`/${url}/${id}`, {_trash: true});
			queryClient.invalidateQueries([type.name, parent_id]);
			return r;
		},
		untrash:async (id)=>{
			let r= await  put(`/${url}/${id}`, {_trash: false});
			queryClient.invalidateQueries([type.name, parent_id]);
			return r;
		},
		delete:async (id)=>{
			let r = await remove(`/${url}/${id}`);
			queryClient.invalidateQueries([type.name, parent_id]);
			return r;
		},
		...replacements
	};
};


export default {post, get, put, remove, upload, init};
